import { IBrand } from 'app/shared/model/brand.model';
import { ChargerType } from 'app/shared/model/enumerations/charger-type.model';

export interface IBrandChargerConfig {
  id?: number;
  kw?: number | null;
  price?: number | null;
  type?: keyof typeof ChargerType | null;
  brand?: IBrand | null;
}

export const defaultValue: Readonly<IBrandChargerConfig> = {};
