import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Charger from './charger';
import ChargerDetail from './charger-detail';
import ChargerUpdate from './charger-update';
import ChargerDeleteDialog from './charger-delete-dialog';

const ChargerRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Charger />} />
    <Route path="new" element={<ChargerUpdate />} />
    <Route path=":id">
      <Route index element={<ChargerDetail />} />
      <Route path="edit" element={<ChargerUpdate />} />
      <Route path="delete" element={<ChargerDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ChargerRoutes;
