import { ICharger } from 'app/shared/model/charger.model';
import { ChargerType } from 'app/shared/model/enumerations/charger-type.model';

export interface IConnector {
  id?: number;
  epdkId?: string | null;
  externalId?: string | null;
  type?: keyof typeof ChargerType | null;
  subType?: string | null;
  price?: number | null;
  kw?: number | null;
  socketNumber?: string | null;
  charger?: ICharger | null;
}

export const defaultValue: Readonly<IConnector> = {};
