import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBrand } from 'app/shared/model/brand.model';
import { getEntities as getBrands } from 'app/entities/brand/brand.reducer';
import { IBrandChargerConfig } from 'app/shared/model/brand-charger-config.model';
import { ChargerType } from 'app/shared/model/enumerations/charger-type.model';
import { getEntity, updateEntity, createEntity, reset } from './brand-charger-config.reducer';

export const BrandChargerConfigUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const brands = useAppSelector(state => state.brand.entities);
  const brandChargerConfigEntity = useAppSelector(state => state.brandChargerConfig.entity);
  const loading = useAppSelector(state => state.brandChargerConfig.loading);
  const updating = useAppSelector(state => state.brandChargerConfig.updating);
  const updateSuccess = useAppSelector(state => state.brandChargerConfig.updateSuccess);
  const chargerTypeValues = Object.keys(ChargerType);

  const handleClose = () => {
    navigate('/brand-charger-config');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getBrands({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.kw !== undefined && typeof values.kw !== 'number') {
      values.kw = Number(values.kw);
    }
    if (values.price !== undefined && typeof values.price !== 'number') {
      values.price = Number(values.price);
    }

    const entity = {
      ...brandChargerConfigEntity,
      ...values,
      brand: brands.find(it => it.id.toString() === values.brand.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          type: 'AC',
          ...brandChargerConfigEntity,
          brand: brandChargerConfigEntity?.brand?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="sarjlanApp.brandChargerConfig.home.createOrEditLabel" data-cy="BrandChargerConfigCreateUpdateHeading">
            <Translate contentKey="sarjlanApp.brandChargerConfig.home.createOrEditLabel">Create or edit a BrandChargerConfig</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="brand-charger-config-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('sarjlanApp.brandChargerConfig.kw')}
                id="brand-charger-config-kw"
                name="kw"
                data-cy="kw"
                type="text"
              />
              <ValidatedField
                label={translate('sarjlanApp.brandChargerConfig.price')}
                id="brand-charger-config-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('sarjlanApp.brandChargerConfig.type')}
                id="brand-charger-config-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {chargerTypeValues.map(chargerType => (
                  <option value={chargerType} key={chargerType}>
                    {translate('sarjlanApp.ChargerType.' + chargerType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="brand-charger-config-brand"
                name="brand"
                data-cy="brand"
                label={translate('sarjlanApp.brandChargerConfig.brand')}
                type="select"
              >
                <option value="" key="0" />
                {brands
                  ? brands.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/brand-charger-config" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BrandChargerConfigUpdate;
