import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Brand from './brand';
import Charger from './charger';
import Config from './config';
import BrandChargerConfig from './brand-charger-config';
import Connector from './connector';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="brand/*" element={<Brand />} />
        <Route path="charger/*" element={<Charger />} />
        <Route path="config/*" element={<Config />} />
        <Route path="brand-charger-config/*" element={<BrandChargerConfig />} />
        <Route path="connector/*" element={<Connector />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
