import brand from 'app/entities/brand/brand.reducer';
import charger from 'app/entities/charger/charger.reducer';
import config from 'app/entities/config/config.reducer';
import brandChargerConfig from 'app/entities/brand-charger-config/brand-charger-config.reducer';
import connector from 'app/entities/connector/connector.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  brand,
  charger,
  config,
  brandChargerConfig,
  connector,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
