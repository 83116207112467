import { IBrandChargerConfig } from 'app/shared/model/brand-charger-config.model';

export interface IBrand {
  id?: number;
  name?: string | null;
  operatorId?: string | null;
  chargerConfigs?: IBrandChargerConfig[] | null;
}

export const defaultValue: Readonly<IBrand> = {};
