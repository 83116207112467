import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './charger.reducer';

export const ChargerDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const chargerEntity = useAppSelector(state => state.charger.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="chargerDetailsHeading">
          <Translate contentKey="sarjlanApp.charger.detail.title">Charger</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="sarjlanApp.charger.name">Name</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.name}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="sarjlanApp.charger.type">Type</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.type}</dd>
          <dt>
            <span id="latitude">
              <Translate contentKey="sarjlanApp.charger.latitude">Latitude</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.latitude}</dd>
          <dt>
            <span id="longitude">
              <Translate contentKey="sarjlanApp.charger.longitude">Longitude</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.longitude}</dd>
          <dt>
            <span id="externalId">
              <Translate contentKey="sarjlanApp.charger.externalId">External Id</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.externalId}</dd>
          <dt>
            <span id="created">
              <Translate contentKey="sarjlanApp.charger.created">Created</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.created ? <TextFormat value={chargerEntity.created} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updated">
              <Translate contentKey="sarjlanApp.charger.updated">Updated</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.updated ? <TextFormat value={chargerEntity.updated} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="epdkId">
              <Translate contentKey="sarjlanApp.charger.epdkId">Epdk Id</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.epdkId}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="sarjlanApp.charger.active">Active</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastFetchTime">
              <Translate contentKey="sarjlanApp.charger.lastFetchTime">Last Fetch Time</Translate>
            </span>
          </dt>
          <dd>
            {chargerEntity.lastFetchTime ? <TextFormat value={chargerEntity.lastFetchTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="address">
              <Translate contentKey="sarjlanApp.charger.address">Address</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.address}</dd>
          <dt>
            <span id="serviceType">
              <Translate contentKey="sarjlanApp.charger.serviceType">Service Type</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.serviceType}</dd>
          <dt>
            <span id="isGreen">
              <Translate contentKey="sarjlanApp.charger.isGreen">Is Green</Translate>
            </span>
          </dt>
          <dd>{chargerEntity.isGreen ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="sarjlanApp.charger.brand">Brand</Translate>
          </dt>
          <dd>{chargerEntity.brand ? chargerEntity.brand.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/charger" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/charger/${chargerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ChargerDetail;
