import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BrandChargerConfig from './brand-charger-config';
import BrandChargerConfigDetail from './brand-charger-config-detail';
import BrandChargerConfigUpdate from './brand-charger-config-update';
import BrandChargerConfigDeleteDialog from './brand-charger-config-delete-dialog';

const BrandChargerConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BrandChargerConfig />} />
    <Route path="new" element={<BrandChargerConfigUpdate />} />
    <Route path=":id">
      <Route index element={<BrandChargerConfigDetail />} />
      <Route path="edit" element={<BrandChargerConfigUpdate />} />
      <Route path="delete" element={<BrandChargerConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BrandChargerConfigRoutes;
