import { IBrand } from 'app/shared/model/brand.model';
import { IConnector } from 'app/shared/model/connector.model';
import { ChargerType } from 'app/shared/model/enumerations/charger-type.model';

export interface ICharger {
  id?: number;
  name?: string | null;
  type?: keyof typeof ChargerType | null;
  latitude?: number | null;
  longitude?: number | null;
  externalId?: string | null;
  created?: string | null;
  updated?: string | null;
  kw?: number;
  price?: number;
  epdkId?: string | null;
  active?: boolean | null;
  lastFetchTime?: string | null;
  address?: string | null;
  serviceType?: string | null;
  isGreen?: boolean | null;
  brand?: IBrand | null;
  connectors?: IConnector[] | null;
}

export const defaultValue: Readonly<ICharger> = {
  active: false,
  isGreen: false,
};
